import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
function LogIn() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://bntserver.bntfoundation.com/LogIn.php?email=${email}&password=${password}`);
            const data = await response.json();
            if (response.ok) {
                alert("success Login")
                navigate('/Home');
                window.location.href = '/Home';
                // Pass the retrieved data to the onLogin callback
            } else {
                alert(data.error); // Display error message if login fails
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };
    return (
        <div className="main">
            <div className="homeContainer">
                <div
                    class="untree_co-hero inner-page container-fluid"
                    style={{
                        backgroundImage: `url('images/studentRegister.jpg')`,
                        zIndex: 1,
                    }}
                >
                    <div class="container-fluid ">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-12">
                                <div class="row justify-content-center ">
                                    <div class="col-lg-6 text-center ">
                                        <h1
                                            class="heading text-white"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                            style={{ marginTop: 140 }}
                                        >
                                            Login
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="untree_co-section logInBox">
                    <div class="container-fluid">
                        <div class="row mb-5 justify-content-center">
                            <div
                                class="col-lg-5 mx-auto order-1"
                                data-aos="fade-up"
                                data-aos-delay="200"
                            >

                                <form class="form-box" onSubmit={handleSubmit}>
                                    <div class="row">
                                        <div class="col-12 mb-3">

                                            <input
                                                type="text"
                                                name="email"
                                                class="form-control"
                                                placeholder="Username"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div class="col-12 mb-3">

                                            <input
                                                type="password"
                                                name="password"
                                                class="form-control"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div class="col-12 mb-1">
                                            <label class="control control--checkbox">
                                                <input type="checkbox" className='mx-2 my-2'/>
                                                <span class="caption">Remember me</span>
                                                <div class="control__indicator"></div>
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <button type='submit' class="btn btn-primary">
                                                Log In
                                            </button>
                                        </div>
                                        {/* <div class="col-12 mt-3">
                                            <h5>If you do not have an account, then</h5>
                                            <Link to={ROUTES.register.name}>
                                                Register Here
                                            </Link>
                                        </div> */}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LogIn;
