import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';

function Register() {
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
    confirmpassword: ''
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().min(5).required(),
    confirmpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(5, 'Password confirmation must be at least 5 characters')
    .required('Required'),
  });
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    


    axios.post("https://bntserver.bntfoundation.com/RegisterPost.php", formData).then((response) => {
        alert('Successfully Registered');
        navigate(ROUTES.logIn.name);
    })
}
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{ backgroundImage: `url('/images/home.jpg')` }}
        >
          <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="mt-5  heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                     Register
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="untree_co-section logInBox">
          <div class="container-fluid">
            <div class="row mb-5 justify-content-center">
              <div
                class="col-lg-7 mx-auto order-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  <Form class="form-box">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          autoComplete="off"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>

                   
                     
                    
                      <div class="col-6 mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          autoComplete="off"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          id="confirmpassword"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          name="confirmpassword"
                        />
                        <ErrorMessage
                          name="confirmpassword"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-1">
                        <label class="control control--checkbox">
                          <span class="caption">Remember me</span>
                          <input type="checkbox" checked="checked" />
                          <div class="control__indicator"></div>
                        </label>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary">
                          Register
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
